<template>
    <div class="bg-login">
        <div>
            <a class="navbar-brand"><img src="../assets/images/logo.png" title="黑龙江省综合交通运输规划计划管理平台" /></a>
            <div class="box" id="login-box">

                <div class="box-content" @keypress="loginPress">
                    <el-row :gutter="0">
                        <el-col :span="12" class="login-ad"><img src="../assets/images/login-ad.png" title="黑龙江省综合交通运输规划计划管理平台" /></el-col>
                        <el-col :span="12">
                            <h3 class="login-tit">用户登录</h3>
                            <el-form ref="form" label-width="120px" class="demo-form">
                                <el-form-item label="账号">
                                    <el-input type="text" v-focus v-model="userInfo.userName" placeholder="输入帐号"></el-input>
                                </el-form-item>
                                <el-form-item label="密码">
                                    <el-input type="password" v-focus v-model="userInfo.passWord" placeholder="输入密码"></el-input>
                                </el-form-item>
                                <el-form-item label="验证码">
                                    <el-col :span="16">
                                        <el-input v-focus type="text" v-model="userInfo.verificationCode" placeholder="输入验证码"></el-input>
                                    </el-col>
                                    <div class="code" @click="() => {getVierificationCode();}">
                                        &nbsp;&nbsp;
                                        <img v-show="codeImgSrc != ''" :src="codeImgSrc" />
                                    </div>
                                </el-form-item>
                                <el-form-item class="login-btn-box">
                                    <el-button type="primary" size="large" :loading="loading" @click="login">
                                        <span v-if="!loading">登录</span>
                                        <span v-else>正在登录...</span>
                                    </el-button>
                                    <!-- <el-link type="primary" :underline="false" class="fright">忘记密码？</el-link> -->
                                    <el-link type="primary" href="/#/register" class="fright">立即注册</el-link>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>

                </div>
            </div>

            <el-footer>
                <p>
                    技术支持
                    <a href="http://www.hljzztech.com/">黑龙江正则科技开发有限公司</a>
                </p>
                <p>{{ version }}</p>
            </el-footer>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: false,
            codeImgSrc: "",
            version: "",
            userInfo: {
                userName: "",
                passWord: "",
                verificationCode: "",
            },
        };
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus();
            },
        },
    },
    created() {
        this.getVierificationCode();
    },
    mounted() {
        document.getElementsByTagName("body")[0].className = "login-body";
        this.version = process.env.VUE_APP_Version;
    },
    destroyed() {
        document.body.removeAttribute("class");
    },

    methods: {
        getVierificationCode() {
            this.http.get("/api/User/getVierificationCode").then((x) => {
                this.codeImgSrc = "data:image/png;base64," + x.img;
                this.userInfo.UUID = x.uuid;
            });
        },
        loginPress(e) {
            console.log(e);
            if (event.keyCode == 13) {
                this.login();
            }
        },
        login() {
            if (
                this.userInfo.userName == "" ||
                this.userInfo.userName.trim() == ""
            )
                return this.$message.error("请输入用户名");
            if (
                this.userInfo.passWord == "" ||
                this.userInfo.passWord.trim() == ""
            )
                return this.$message.error("请输入密码");
            if (
                this.userInfo.verificationCode == "" ||
                this.userInfo.verificationCode.trim() == ""
            )
                return this.$message.error("请输入验证码");
            this.loading = true;
            this.http
                .post("/api/user/login", this.userInfo, "正在登录....")
                .then((result) => {
                    if (!result.status) {
                        this.loading = false;
                        this.getVierificationCode();
                        return this.$message.error(result.message);
                    }
                    // this.$message.info("登陆成功,正在跳转!");

                    this.$store.commit("setUserInfo", result.data);
                    document.cookie = "BearerToken="+'Bearer '+result.data.token+';domain=hljzztech.com;';
                    this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                        document.cookie = "accounts="+res.data.userName+';domain=hljzztech.com;';
                        document.cookie = "roleName="+res.data.roleName+';domain=hljzztech.com;';
                    })

                    // this.http.post("/api/menu/getTreeMenu ", {}).then((r) => {
                    //     localStorage.setItem("menuAuth", JSON.stringify(r));
                    // });

                    this.$router.push({ path: "/Search" });
                    // this.$router.push({ path: "/Home" });
                });
        },
    },
};
</script>
<style>
/* @import "../assets/css/font-awesome.min.css";
@import "../assets/css/index.css";
@import "../assets/css/style.css"; */
</style>
